import { Injectable, inject } from '@angular/core';
import { CookieBrowserService } from '@core/service/cookie/cookie.service';
import { LocalstorageService } from '@core/service/localstorage';
import { SessionStorageService } from '@core/service/session-storage/session-storage.service';
import { StorageService } from '@shared/interfaces/storage-services.interface';

@Injectable({
    providedIn: 'root',
})
export class AppStorageService implements StorageService {
    private _cookie = inject(CookieBrowserService);
    private _local = inject(LocalstorageService);
    private _session = inject(SessionStorageService);

    public currentStorage: StorageService = this._local;

    public getItem(name: string): string | null {
        return this.currentStorage.getItem(name);
    }

    public setItem(key: string, value: any): void {
        return this.currentStorage.setItem(key, value);
    }

    public key(index: number): string | null {
        return this.currentStorage.key(index);
    }

    public removeItem(key: string): void {
        return this.currentStorage.removeItem(key);
    }

    public clear(): void {
        return this.currentStorage.clear();
    }
}
