import { Injectable } from '@angular/core';
import { StorageService } from '@shared/interfaces/storage-services.interface';

@Injectable({
    providedIn: 'root',
})
export class LocalstorageService implements StorageService {
    private ls = localStorage;

    /**
     * Возращает данные по ключу
     *
     * @returns {any[]}
     */
    public getItem(name: string): string | null {
        return this.ls.getItem(name);
    }

    /**
     * Возращает все данные
     *
     * @returns {any[]}
     */
    public getList(): any[] {
        const res: any[] = [];

        for (let i = 0, len = this.ls.length; i < len; i++) {
            const key: string | null = this.ls.key(i);
            if (key) {
                const value = this.ls[key];
                res.push(value);
            }
        }

        return res;
    }

    /**
     * Запись
     *
     * @param key
     * @param value
     */
    public setItem(key: string, value: any): void {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        this.ls.setItem(key, value);
    }

    /**
     * Название параметра по индексу
     *
     * @param index
     * @returns name
     */
    public key(index: number): string | null {
        return this.ls.key(index);
    }

    /**
     * Удаление
     *
     * @param {string} key
     */
    public removeItem(key: string) {
        this.ls.removeItem(key);
    }

    /**
     * Очистка хранилища
     */
    public clear(): void {
        this.ls.clear();
    }
}
