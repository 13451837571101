import { Injectable, inject } from '@angular/core';
import { StorageService } from '@shared/interfaces/storage-services.interface';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class CookieBrowserService implements StorageService {
    private cookieService = inject(CookieService);

    [index: number]: string;
    [key: string]: any;

    public length: number = 0;

    public clear(): void {
        this.cookieService.deleteAll();
    }

    public getItem(key: string): string {
        return this.cookieService.get(key);
    }

    public key(index: number): string {
        const all = this.cookieService.getAll();
        return all[index];
    }

    public removeItem(key: string): void {
        this.cookieService.set(key, '', new Date(Date.now() - 15), '/');
        this.cookieService.delete(key, '/');
    }

    public setItem(key: string, data: string): void {
        this.cookieService.set(
            key,
            data,
            new Date(Date.now() + 3600 * 24 * 1000 * 365),
            '/'
        );
    }
}
