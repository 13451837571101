import { Injectable } from '@angular/core';
import { environment } from '@core/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class Config {
    public setting: { [key: string]: any } = environment;

    public get(key: string) {
        if (key === '') {
            return '';
        }

        if (Object.keys(this.setting).includes(key)) {
            return this.setting[key];
        }

        return '';
    }

    public getAll() {
        return this.setting;
    }
}
