import { Injectable } from '@angular/core';
import { StorageService } from '@shared/interfaces/storage-services.interface';

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService implements StorageService {
    [index: number]: string;

    [key: string]: any;

    length: number = 0;
    cookies: any;

    clear(): void {
        sessionStorage.clear();
    }

    getItem(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    key(index: number): string | null {
        return sessionStorage.key(index);
    }

    removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

    setItem(key: string, value: any): void {
        let tmp = value;
        if (typeof value === 'object') {
            tmp = JSON.stringify(value);
        }
        sessionStorage.setItem(key, tmp.toString());
    }
}
